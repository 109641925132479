<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <silver-coin-box
      :columns="columns"
      :rows="giftList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showToolbar="true"
      :showSearch="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      addText="Download MIS"
      downloadText="DownloadList"
      emptyText="No Data Found."
      @download="onDownload"
      @add="downloadMis"
    >
    </silver-coin-box>
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import SilverCoinBox from '../../components/SilverCoinBox.vue';

export default {
  components: { SilverCoinBox },
  name: 'SilverCoinList',
  metaInfo() {
    return {
      title: 'Gift Issuance',
      meta: [
        {
          name: 'gift-distribute',
          content: `Download mis-gift`
        }
      ]
    };
  },
  data() {
    return {
      title: 'Gift Issuance',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Bill No',
          class: { 'text-center': true },
          textKey: 'billNumber',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Till No',
          class: { 'text-center': true },
          textKey: 'tillNumber',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Bill Amount',
          class: { 'text-center': true },
          textKey: 'billAmount',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Customer Mobile',
          class: { 'text-center': true },
          textKey: 'customerMobile',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Bill Date',
          class: { 'text-center': true },
          textKey: 'billDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Promo Id',
          class: { 'text-center': true },
          textKey: 'giftPromoId',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Gift Name',
          class: { 'text-center': true },
          textKey: 'giftName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        }
      ],
      searchItems: ['Bill Number']
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listGift({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listGift({ query: this.$route.query });
    }
    // this.downloadSilver({ query: this.$route.query });
  },
  beforeRouteUpdate(to, from, next) {
    this.listGift({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('mis', ['downloadSilver']),
    ...mapActions('gift', ['listGift', 'download']),
    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    downloadMis() {
      // if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      //   this.downloadSilver({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      // } else {
      //   this.downloadSilver({
      //     query: {
      //       ...this.$route.query,
      //       page_size: 99999,
      //       page: 1,
      //       startdate: moment().format('YYYY-MM-DD'),
      //       enddate: moment().format('YYYY-MM-DD')
      //     }
      //   });
      // }
      let csv = '';
      csv += `Region, Telecaller Logged In, Call Assigned,Call Made,Call Converted,Sale Value\n`;
      csv += `,,,,,\n`;
      csv += `,,,,,\n`;
      csv += `,,,,,\n`;
      csv += `Region,ASM,Telecaller Logged In,Call Assigned,Call Made,Call Converted,Sale Value\n`;
      csv += `,,,,,,\n`;
      csv += `,,,,,,\n`;
      csv += `,,,,,,\n`;
      csv += `Region,Store Code,Store Name,Telecaller Name,Call Assigned,Call Made,Call Converted,Sale Value\n`;

      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'GiftReport.csv';
      anchor.click();
    }
  },
  computed: {
    ...mapState('mis', ['silverList']),
    ...mapState('gift', ['loading', 'baseUrl', 'pagination', 'giftList', 'downloadGiftList'])
  },
  watch: {
    silverList(newValue) {
      if (newValue) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv += 'Store Id, Issued Coin\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([0, 1].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'SilverCoinMISReport.csv';
        anchor.click();
      }
    },
    downloadGiftList(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv +=
          'Row Number,Store Id,Bill No,Till No,Bill Amount,Customer Mobile,Bill Date,Promo Id,Gift Name,Submitted By,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Gift-Issuance.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style></style>
