<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <role-box
      :columns="columns"
      :rows="rolesList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="false"
      :showPagination="false"
      :pagination="pagination"
      addText="Create new Role"
      emptyText="No Role found. Please add new Role."
      @add="onAdd"
      @edit="onEdit"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </role-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RoleBox from '@/components/RoleBox.vue';
import router from '@/router';

export default {
  name: 'Role',
  components: { RoleBox },
  metaInfo() {
    return {
      title: 'Role',
      meta: [
        {
          name: 'description',
          content: `Manage Role`
        }
      ]
    };
  },
  mounted() {
    this.listRole({ router });
  },
  data() {
    return {
      title: 'Roles List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'name',
          width: '23%'
        },
        {
          type: 'string',
          headerText: 'Description',
          class: { 'text-center': true },
          textKey: 'description',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'permission',
          class: { 'text-center': true },
          textKey: 'permissions',
          width: '27%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '17%',
          functions: {
            edit: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('role', ['loading', 'baseUrl', 'roles', 'pagination', 'rolesList'])
  },
  methods: {
    ...mapActions('role', ['listRole']),
    onAdd() {
      router.push('/role/new');
    },
    onEdit({ row }) {
      router.push(`/role/${row.id}`);
    }
  }
};
</script>
