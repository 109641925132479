<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="tags"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="true"
      :searchItems="searchItems"
      :steps="steps"
      :callbacks="callbacks"
      addText="Create new Tag"
      emptyText="No Tag found. Please add new Tag."
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'Tags',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Tags',
      meta: [
        {
          name: 'description',
          content: `Manage Tags`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedTags') !== 'true') {
    //   this.$tours.myTour.start();
    // }

    if (_.isEmpty(this.$route.query)) {
      this.listTags({
        query: {
          ...this.$route.query
          // startdate: moment().subtract(2, 'year').format('YYYY-MM-DD'),
          // enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listTags({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTags({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Tags List',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedTags', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Tag Category or Tag to Filter the List of Tags.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Create new Tag.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Tag Category',
          class: { 'text-center': true },
          textKey: 'tagCategory',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Tags',
          class: { 'text-center': true },
          textKey: 'tags',
          width: '23%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '27%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '17%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ],
      searchItems: ['Tag Category', 'Tag']
    };
  },
  computed: {
    ...mapState('tags', ['loading', 'baseUrl', 'tags']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('tags', ['listTags', 'deleteOne']),
    onAdd() {
      router.push('/tags/new');
    },
    onEdit({ row }) {
      router.push(`/tags/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
