<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="notesList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showReportDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :showFilter="true"
      :filterOptions="filterOptions"
      :steps="steps"
      :callbacks="callbacks"
      addText="Create new Notes"
      :downloadReportText="'Download Reports'"
      downloadText="Download Notes"
      emptyText="No Notes found. Please add new Notes."
      @add="onAdd"
      @download="onExportCSV"
      @delete="onDelete"
      @open="onView"
      @downloadreport="onDownloadReport"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import { groupBy } from 'lodash';

import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'Feedback',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Notes',
      meta: [
        {
          name: 'description',
          content: `Manage Notes`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedNotes') !== 'true') {
    //   this.$tours.myTour.start();
    // }

    this.listTags({ router });
    if (_.isEmpty(this.$route.query)) {
      this.listNotes({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listNotes({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listNotes({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Notes',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedNotes', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Store Id or Sub Department to Filter the List of Notes.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select Tag to further refine search Results.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
          content: `Download the List of Notes in CSV format.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="7"]',
          content: 'View the Notes details in new page.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Department',
          class: { 'text-center': true },
          textKey: 'roles',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Sub Department',
          class: { 'text-center': true },
          textKey: 'submittedBy',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Tags',
          class: { 'text-center': true },
          textKey: 'tags',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Date',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Content',
          class: { 'text-center': false },
          htmlKey: 'content',
          width: '35%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            open: true,
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Store Id', 'Department', 'Sub Department', 'ASM Name'],
      filterOptions: []
    };
  },
  computed: {
    ...mapState('notes', [
      'loading',
      'baseUrl',
      'notesList',
      'pagination',
      'downloadNotesList',
      'notesReport',
      'taggedReport'
    ]),
    ...mapState('tags', ['tagList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    tagList(value) {
      this.filterOptions = [{ value: null, text: 'Select Tag Filter' }];
      const filter2 = value.find(i => {
        return i.tagCategory === 'Notes';
      }) || { tags: [] };
      this.filter = filter2.tags.map(i => ({ value: `Tags-${i}`, text: i }));
      this.filterOptions = [...this.filterOptions, ...this.filter];
    },
    downloadNotesList(newValue) {
      if (newValue.length) {
        let csv =
          'Row Number,Store Id,Submitted By,Department,Sub Department,Tags, Date,Content,Status, Updated At,Unique Id\n';
        newValue.forEach(row => {
          const temp = [];
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
              temp.push(val);
            }
            if ([8].includes(index)) {
              val.forEach((i, index) => {
                if (i.text !== '') {
                  if (index > 0) {
                    temp.forEach(j => {
                      csv += `"${j}"`;
                      csv += ',';
                    });
                  }
                  const textOfNote = i.text === undefined ? '-' : i.text;
                  const uniqueId = i._id === undefined ? '-' : i._id;
                  const updatedTime =
                    row.updatedAt === undefined ? '-' : moment(row.updatedAt).format('DD-MM-YYYY hh:mm:  A');
                  const noteResponse =
                    i.response === undefined ? 'Not Attempted' : i.response === null ? 'Not Attempted' : i.response;
                  csv += `"${textOfNote}","${noteResponse}`;
                  csv += `","${updatedTime}","${uniqueId}"`;
                  csv += '\n';
                }
              });
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Notes.csv';
        anchor.click();
      }
    },
    notesReport(newvalue) {
      if (newvalue) {
        const live = newvalue.liveData;
        const Ld = newvalue.lastDay;
        const MTD = newvalue.monthTillDate;

        const liveData = live.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          department: i.department,
          subDepartment: i.subDepartment,
          currentDay: i.liveData
        }));

        const lastDay = Ld.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          department: i.department,
          subDepartment: i.subDepartment,
          lastDay: i.LD
        }));

        const monthTillDate = MTD.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          department: i.department,
          subDepartment: i.subDepartment,
          monthTillDate: i.MTD
        }));

        const arr = [...liveData, ...lastDay, ...monthTillDate];
        const uniqueEmployees = [];
        arr.forEach(i => {
          if (!uniqueEmployees.includes(i.employeeName)) {
            uniqueEmployees.push(i.employeeName);
          }
        });
        const modifiedData = uniqueEmployees.map(i => ({
          employeeName: i,
          region: arr.find(j => j.employeeName === i).region,
          department: arr.find(j => j.employeeName === i).department,
          subDepartment: arr.find(j => j.employeeName === i).subDepartment,
          currentDay: [],
          lastDay: [],
          monthTillDate: []
        }));
        arr.forEach(i => {
          const index = modifiedData.findIndex(j => j.employeeName === i.employeeName);
          if (i.currentDay) {
            modifiedData[index].currentDay.push(i.currentDay);
          }
          if (i.lastDay) {
            modifiedData[index].lastDay.push(i.lastDay);
          }
          if (i.monthTillDate) {
            modifiedData[index].monthTillDate.push(i.monthTillDate);
          }
        });

        let csv = '';
        csv += `,,Report Date  ${moment().format('DD-MM-YYYY')} \n`;
        csv += `,,,\n`;
        csv += 'Region,Name Of Employee,Department,Sub-Department,Live,Last Day,MTD\n';
        modifiedData.forEach(i => {
          csv += `"${i.region}",${i.employeeName},"${i.department}","${i.subDepartment}",${
            i.currentDay.length === 0 ? 0 : i.currentDay
          },${i.lastDay.length === 0 ? 0 : i.lastDay},${i.monthTillDate.length === 0 ? 0 : i.monthTillDate}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Notes Report.csv';
        anchor.click();
      }
    },
    taggedReport(newValue) {
      if (newValue) {
        let csv = '';
        csv += `,,Report From   ${moment(this.$route.query.startdate).format('DD-MM-YYYY')}   to   ${moment(
          this.$route.query.enddate
        ).format('DD-MM-YYYY')} \n`;
        csv += `,,,\n`;
        csv += `Date,Store Id,Tagged By, Tagged To, Notes\n`;
        newValue.forEach(i => {
          if (i.notes.length > 1) {
            i.notes.forEach(j => {
              csv += `"${moment(i.date).format('DD-MM-YYYY')}","${i.storeid}","${
                i.taggedBy === 'NA' ? '-' : i.taggedBy
              }","${i.taggedTo === 'NA' ? '-' : i.taggedTo}","${j}"\n`;
            });
          } else {
            csv += `"${moment(i.date).format('DD-MM-YYYY')}","${i.storeid}","${
              i.taggedBy === 'NA' ? '-' : i.taggedBy
            }","${i.taggedTo === 'NA' ? '-' : i.taggedTo}","${i.notes}"\n`;
          }
          csv += `,,,\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Tagged Report.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('notes', ['listNotes', 'deleteOne', 'getone', 'download', 'downloadNotesReport', 'taggingReport']),
    ...mapActions('tags', ['listTags']),
    onAdd() {
      router.push('/notes/new');
    },
    // onEdit({ row }) {
    //   router.push(`/notes/${row.id}`);
    // },
    onView({ row }) {
      router.push(`/notes/${row.id}`);
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },

    onDownloadReport({}) {
      this.downloadNotesReport({ router });
      this.taggingReport({ query: { ...this.$route.query }, router });
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
